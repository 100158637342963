import React from 'react';
import logo from './logo.svg';
import './App.css';
import { API } from './api';

export default class Typewriter extends React.Component<any, any> {
  state = {
    displayed: '',
    index: 0,
  }

  isRunning = false;

  componentDidMount() {
    this.tick();
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.text !== this.props.text) {
      this.setState({
        displayed: '',
        index: 0,
      }, () => {
        if (!this.isRunning) {
          this.tick();
        }
      });
    }
  }

  private tick() {
    this.isRunning = true;

    if (this.state.index >= this.props.text.length) {
      this.isRunning = false;
      return;
    }

    this.setState({
      displayed: this.state.displayed + this.props.text[this.state.index],
      index: this.state.index + 1,
    }, () => {
      setTimeout(() => {
        this.tick();
      }, 50 + (Math.random() * 150));
    });
  }

  render() {
    return (<span className={`${this.props.className || ''} text`}>{this.state.displayed}</ span>
    );
  }
}
