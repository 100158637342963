import React from 'react';
import ReactMapboxGl, { Layer, Feature, GeoJSONLayer, Source } from 'react-mapbox-gl';
import * as MapboxGL from 'mapbox-gl';
import DrawControl from 'react-mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import 'antd/dist/antd.css';
import './App.css';
import { Card, Button, Checkbox, Switch, Spin, Icon, Select, Divider, Skeleton } from 'antd';
import { API } from './api';

const { Option } = Select;

// tslint:disable-next-line:no-var-requires
const { token, styles } = require('./config.json');
// tslint:disable-next-line:no-var-requires
const geojson = {}; // require('./geojson.json');

const Map = ReactMapboxGl({ accessToken: token });

const symbolLayout: MapboxGL.SymbolLayout = {
  'text-field': '{place}',
  'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
  'text-offset': [0, 0.6],
  'text-anchor': 'top'
};
const symbolPaint: MapboxGL.SymbolPaint = {
  'text-color': 'white'
};

const circleLayout: MapboxGL.CircleLayout = { visibility: 'visible' };
const circlePaint: MapboxGL.CirclePaint = {
  'circle-color': 'white'
};
const mapStyle = {
  flex: 1
};;

const fake_geojson_data = {
  'type': 'FeatureCollection',
  'features': [
    {
      'type': 'Feature',
      'geometry': {
        'type': 'LineString',
        'coordinates': [
          [
            -77.01239,
            38.91275
          ],
          [
            -77.00405,
            38.93800
          ]
        ]
      }
    }
  ]
};

const linePaint: MapboxGL.LinePaint = {
  'line-color': 'red',
  'line-width': 5
};

enum ADMIN_STATE {
  VIEW_MODE,
  EDIT_MODE,
}


export default class StatusView extends React.Component<any, any> {
  state = {
  }
  static coordsToObj(coords: Coordinates) {
    return ({
      accuracy: coords.accuracy || null,
      altitude: coords.altitude || null,
      altitudeAccuracy: coords.altitudeAccuracy || null,
      heading: coords.heading || null,
      latitude: coords.latitude || null,
      longitude: coords.longitude || null,
      speed: coords.speed || null,
    })
  }

  getAllFenceLabels() {
    return this.props.fences.map((x: any) => {
      return x.properties.name || x.id;
    });
  }


  render() {
    return (
      <div className="fart">
        <header className="fart-header">
          <Card key={this.props.currentDisplayLabel} style={{
            maxWidth: '500px',
            width: '95vw',
            margin: 'calc(-2em - 6vh) auto 0',
            zIndex: 500,
            filter: 'drop-shadow(0px 0px 12px rgba(0,0,0,0.25))',
          }}
          >

            {this.props.currentDisplayLabel !== 'Loading..' ?
              <>
                <p>You {this.props.wasLastSeen ? 'were last seen' : 'are currently'} at the </p>
                <h1>
                  {
                    this.props.autoUpdate ? this.props.currentDisplayLabel :
                      <Select
                        size="large"
                        defaultValue={this.props.currentDisplayLabel}
                        style={{ width: '100%' }}
                        onChange={(val: string) => {
                          this.updateForcedLabel(val);
                        }}
                      >
                        <Option key={"unavailable"} value="Unavailable">Unavailable</Option>
                        {
                          this.getAllFenceLabels().map((lbl: string) =>
                            <Option value={lbl} key={lbl}>
                              {lbl}
                            </Option>
                          )
                        }
                      </Select>
                  }
                </h1>
              </>
              : <Skeleton active />
            }

          </Card>


        </header>
      </div>
    );
  }

  private updateForcedLabel(label: string) {
    fetch(API.getRoute('force'), {
      method: 'POST',
      body: JSON.stringify({ label }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    })
      .then(x => x.json())
      .then(data => {
        console.log('success forcing label', data);
      }).catch(err => {
        console.log('error forcing label', err);
      });
  }
}
