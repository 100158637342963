import React from 'react';
import { Form, Alert } from 'antd';

export default class OfflineNotification extends React.Component<any, any> {
  state = {
    isOffline: false,
  }

  componentDidMount() {
    window.addEventListener('online', () => {
      this.setState({ isOffline: false });
    });
    window.addEventListener('offline', () => {
      this.setState({ isOffline: true, });
    });

    if (!window.navigator.onLine) {
      setTimeout(() => {
        this.setState({ isOffline: true, });
      }, 100);
    }
  }

  render(){
    if(!this.state.isOffline){
      return null;
    }

    return (
      <Alert
        type="error"
        message="You are not connected to the internet."
        banner
      />
    )
  }
}