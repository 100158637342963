
import React from 'react';
import { Popconfirm, message, Drawer, Badge, Layout, Button, Radio, Icon, Tooltip, Switch, Card, Form, Checkbox } from 'antd';
import ReactMapboxGl, { Layer, Feature, GeoJSONLayer, Source } from 'react-mapbox-gl';
import * as MapboxGL from 'mapbox-gl';
import DrawControl from 'react-mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import 'antd/dist/antd.css';
import './App.css';
import { API } from './api';
import GPS from './GPS';
import StatusView from './StatusView';
import EditView from './EditView';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import App from './App';
import EditForm from './EditForm';
import { centerOnAlden } from './fixtures';
import OfflineNotification from './OfflineNotification';
import ButtonGroup from 'antd/lib/button/button-group';
const polylabel: any = require('@mapbox/polylabel');

const { token, styles } = require('./config.json');
const Map = ReactMapboxGl({ accessToken: token });

const symbolLayout: MapboxGL.SymbolLayout = {
  'text-field': '{place}',
  'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
  'text-offset': [0, 0.6],
  'text-anchor': 'top'
};
const symbolPaint: MapboxGL.SymbolPaint = {
  'text-color': 'white'
};

const circleLayout: MapboxGL.CircleLayout = { visibility: 'visible' };
const circlePaint: MapboxGL.CirclePaint = {
  'circle-color': 'white'
};
const mapStyle = {
  flex: 1
};

const radioStyle = {
  display: 'block',
  minHeight: '30px',
};

const { Header, Footer, Sider, Content } = Layout;

enum ADMIN_STATE {
  STATUS_VIEW,
  EDIT_VIEW,
  SETTINGS_VIEW,
}


export default class Admin extends React.Component {
  state = {
    mode: ADMIN_STATE.STATUS_VIEW,
    currentUserCoords: {
      longitude: centerOnAlden[0],
      latitude: centerOnAlden[1],
    } as Coordinates,
    positionLabel: 'Loading..', // loaded form server
    wasLastSeen: false,
    userFences: [] as any[], // loaded from server
    localFences: null, // local edits to be pushed to server
    isUpdatingPosition: false,
    center: centerOnAlden,
    isTrackingCenter: true,
    prefs: {
      autoUpdate: false,
      useLastFence: false,
      showLastSeenMessage: true,
    },
    showEditDrawer: false,
    currentSelection: null,
    showOptionsPanel: false,

    hasGeneralError: false,
  }

  constructor(props: any) {
    super(props);
    this.state.prefs = JSON.parse(localStorage.getItem('witwimm-prefs') || 'null') || this.state.prefs;
  }

  private drawControlRef: any = null;


  private metersToPixelsAtMaxZoom = (meters: number, latitude: number) => {
    return meters / 0.075 / Math.cos(latitude * Math.PI / 180)
  };


  private _getTimer: any;
  private getFenceData() {
    clearTimeout(this._getTimer);
    this._getTimer = setTimeout(() => {
      clearTimeout(this._getTimer);
      this._getTimer = null;
      this._getFenceData();
    }, 1000);
  }

  private _getFenceData() {
    fetch(API.getRoute('geo/fence'))
      .then(x => x.json())
      .then(data => {
        this.setState({
          userFences: data.fences || [],
        }, () => {
          this.drawFences();
        });
      }).catch(err => {
        console.log('error getting fence data', err);
      });
  }

  private getPreferences() {
    fetch(API.getRoute('prefs'))
      .then(x => x.json())
      .then(data => {
        this.setState({
          prefs: {
            ...data.prefs,
          }
        }, this.savePreferences);
      }).catch(err => {
        console.log('error getting user prefs', err);
      });
  }

  private savePreferences() {
    fetch(API.getRoute('prefs'), {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(this.state.prefs),
    })
      .then(x => x.json())
      .then(data => {
        this.setState({
          prefs: {
            ...data.prefs,
          }
        });
      }).catch(err => {
        console.log('error saving user prefs', err);
      });
  }

  private disableForcedLabel() {
    fetch(API.getRoute('force/stop'), {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    })
      .then(x => x.json())
      .then(data => {
        console.log('success stopping label', data);
        this.getFenceData();
        this.getSavedLabel();
      }).catch(err => {
        console.log('error stopping label', err);
      });
  }

  private postFenceUpdates() {
    fetch(API.getRoute('geo/fences'), {
      method: 'POST',
      body: JSON.stringify(this.state.localFences),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    })
      .then(x => x.json())
      .then(data => {
        this.setState({
          userFences: data.fences || [],
          localFences: null,
        }, () => {
          this.toggleMode();
          this.getSavedLabel();
        });
      }).catch(err => {
        console.log('error getting fence data', err);
      });
  }
  getSavedLabel = () => {
    fetch(API.getRoute('current/label'))
      .then(x => x.json())
      .then(res => {
        this.setState({
          positionLabel: res.label,
          wasLastSeen: res.lastSeen,
        });
      }).catch(err => {
        console.log('error on getting position label', err);
      });
  }

  registerPositionWithServer = (coords: Coordinates = this.state.currentUserCoords) => {
    fetch(API.getRoute('current'), {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(StatusView.coordsToObj(coords)),
    })
      .then(x => x.json())
      .then(res => {
        this.setState({
          usersIntersectedFences: res.user_intersected_fences || [],
          positionLabel: res.label,
          wasLastSeen: res.lastSeen,
        });
      }).catch(err => {
        console.log('error on sending user position', err);
      });
  }

  onGPSUpdate(pos: Coordinates) {
    this.setState({
      currentUserCoords: pos,
      center: [pos.longitude, pos.latitude],
    });

    if (this.state.prefs.autoUpdate) {
      this.registerPositionWithServer(pos);
    }
  }

  onLocalMapUpdate = (...args: any[]) => {
    const localFences = this.drawControlRef.draw.getAll().features;
    this.setState({
      localFences,
    })
  }

  onMapSelection = (evt: any, andShowDrawer: boolean = false) => {
    const selectedFeature = evt.features[0];

    if (this.state.mode !== ADMIN_STATE.EDIT_VIEW) {
      // clear selection
      this.drawControlRef.draw.changeMode('simple_select', { featureIds: [] });
      return;
    }

    this.setState({
      currentSelection: selectedFeature,
      showEditDrawer: andShowDrawer ? true : this.state.showEditDrawer,
    });
  }

  componentDidMount() {
    this.getFenceData();
    this.getSavedLabel();
    this.getPreferences();

    this.recenterOnUser(false);

    GPS.onUpdate(this.onGPSUpdate.bind(this));

    if (this.state.prefs.autoUpdate) {
      this.startGPSWatch();
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.prefs.autoUpdate !== this.state.prefs.autoUpdate) {
      if (this.state.prefs.autoUpdate) {
        this.startGPSWatch();
        this.disableForcedLabel();
      } else {
        this.stopGPSWatch();
      }
    }

    if (prevState.positionLabel !== this.state.positionLabel) {
      this.triggerNotification();
    }

    if (JSON.stringify(prevState.prefs) !== JSON.stringify(this.state.prefs)) {
      this.savePreferences();
    }
  }

  private _noteTimer: any = null;
  private triggerNotification() {
    clearTimeout(this._noteTimer);
    this._noteTimer = setTimeout(() => {
      // These do nothing and just spam the user
      Notification.requestPermission().then((permission) => {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          new Notification(`WITWIMM - Just checked in at ${this.state.positionLabel}`);
        }
      });
    }, 3000);
  }

  startGPSWatch() {
    GPS.startWatchingPosition();
    this.setState({
      isUpdatingPosition: true,
    });
  }

  stopGPSWatch() {
    GPS.stopWatchingPosition();
    this.setState({
      isUpdatingPosition: false,
    });
  }

  recenterOnUser(andContinueTracking = true) {
    this.setState({
      center: [this.state.currentUserCoords.longitude, this.state.currentUserCoords.latitude],
      isTrackingCenter: andContinueTracking,
    })


    GPS.getCurrentPosition().then((result: Coordinates) => {
      this.setState({
        isTrackingCenter: andContinueTracking,
        currentUserCoords: result,
        isUpdatingPosition: this.state.prefs.autoUpdate,
        center: [result.longitude, result.latitude]
      });
    }).catch((err: PositionError) => {
      console.log('error recentering', err.message, err.code, err.PERMISSION_DENIED, err.POSITION_UNAVAILABLE);
    })

  }

  onManualUpdateButton() {
    this.setState({
      isUpdatingPosition: true,
    });


    GPS.getCurrentPosition().then((result: Coordinates) => {
      this.setState({
        currentUserCoords: result,
        isUpdatingPosition: this.state.prefs.autoUpdate,
        center: [result.longitude, result.latitude]
      });
      this.registerPositionWithServer(result);
    }).catch((err: PositionError) => {
      console.log('error', err.message, err.code, err.PERMISSION_DENIED, err.POSITION_UNAVAILABLE);
    })
  }

  onViewChange = (evt: any) => {
    console.log(evt.target.value);
    this.setState({
      mode: evt.target.value as ADMIN_STATE,
    });
  }


  private getCurrentLongLat(): [number, number] {
    if (this.state.currentUserCoords.latitude) {
      return [this.state.currentUserCoords.longitude, this.state.currentUserCoords.latitude];
    } else {
      return this.state.center;
    }
  }


  render() {
    const { isUpdatingPosition } = this.state;

    const printedLabels: any = ((this.state.mode === ADMIN_STATE.EDIT_VIEW ?
      (this.state.localFences || this.state.userFences) : this.state.userFences) || []).map((fence: any, idx: number) => {
        const centerPt = polylabel(fence.geometry.coordinates, 1.0);
        return (
          <Layer
            type="symbol"
            layout={{
              'text-field': fence.properties.name || 'noname',
              'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
              'text-offset': [0, -0.5],
              'text-anchor': 'top'
            }}
            paint={{/* 'text-color': 'white' */ }}
          >
            <Feature coordinates={centerPt} />
          </Layer>
        )
      });

    return (
      <Layout>
        <OfflineNotification />

        {this.state.hasGeneralError && "There was an error! Refresh the page and/or check the console for more info."}

        {/* <App /> */}

        <Header style={{
          background: '#fcfcfc',
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0 2em',
          zIndex: 1001,
          boxShadow: '0px 2px 2px rgba(0,0,0,0.15)',
        }}>
          <div>
            <Button type={this.state.showOptionsPanel ? "primary" : undefined} style={{ marginRight: '0.5em' }} shape="circle" icon="menu" onClick={this.toggleOptionsPanel} />
            <Button type={this.state.mode === ADMIN_STATE.EDIT_VIEW ? "primary" : undefined} style={{ marginRight: '0.5em' }} shape="circle" icon="edit" onClick={this.toggleMode} />
            <Tooltip placement="bottom" title={'Refresh GPS Location'}>
              <Button
                shape="circle"
                onClick={() => this.recenterOnUser()}
              >
                <Icon type={"fullscreen-exit"} />
              </Button>
            </Tooltip>
          </div>

          <div>
            <label>
              <Switch
                checkedChildren="AUTO"
                unCheckedChildren="MAN"
                checked={this.state.prefs.autoUpdate}
                onChange={(val: boolean) => {
                  this.setState({
                    prefs: {
                      ...this.state.prefs,
                      autoUpdate: val,
                    },
                  }, () => {
                    localStorage.setItem('witwimm-prefs', JSON.stringify(this.state.prefs));
                  });
                }}
              />
            </label>
            <Tooltip placement="bottom" title={this.state.prefs.autoUpdate ? 'Auto-updating..' : 'Update Location'}>
              <Button
                style={{ marginLeft: '1em' }}
                type={isUpdatingPosition ? "primary" : undefined}
                shape="circle"
                onClick={this.onManualUpdateButton.bind(this)}
              >
                <Icon theme={isUpdatingPosition ? undefined : "filled"} type={isUpdatingPosition ? "sync" : "environment"} spin={isUpdatingPosition} />
              </Button>
            </Tooltip>
          </div>


        </Header>
        <Content style={{ minHeight: '100vh', position: 'relative' }}>
          <Drawer
            title="Settings"
            placement="left"
            closable={false}
            width={"75%"}
            onClose={() => { this.setState({ showOptionsPanel: false }); }}
            visible={this.state.showOptionsPanel}
            getContainer={false}
            style={{ position: 'absolute' }}
            drawerStyle={{ maxWidth: '600px' }}
          >
            <Form>
              <Form.Item label="Location Updates">
                <Radio.Group onChange={(evt) => {
                  this.setState({
                    prefs: {
                      ...this.state.prefs,
                      autoUpdate: evt.target.value === 'true',
                    }
                  }, this.savePreferences)

                }} value={this.state.prefs.autoUpdate.toString()}>
                  <Radio style={radioStyle} value={"true"}>
                    Automatically update displayed location when app is open.
                  </Radio>
                  <Radio style={radioStyle} value={"false"}>
                    Manually choose displayed location.
                  </Radio>
                </Radio.Group>
              </Form.Item>


              <Form.Item label="When I'm outside all fences">
                <Radio.Group onChange={(evt) => {
                  this.setState({
                    prefs: {
                      ...this.state.prefs,
                      useLastFence: evt.target.value === 'true',
                    }
                  }, this.savePreferences)

                }} value={this.state.prefs.useLastFence.toString()}>
                  <Radio style={radioStyle} value={"false"}>
                    Mark me as "Unavailable"
                  </Radio>
                  <Radio style={radioStyle} value={"true"}>
                    Continue to show me at my last fence

                    <Form.Item style={{ paddingLeft: '2em' }}>
                      <Checkbox
                        onChange={(evt) => {
                          this.setState({
                            prefs: {
                              ...this.state.prefs,
                              showLastSeenMessage: evt.target.checked,
                            }
                          }, this.savePreferences)
                        }}
                        disabled={!this.state.prefs.useLastFence}
                        defaultChecked={this.state.prefs.showLastSeenMessage}>Show "Last seen.." message</Checkbox>
                    </Form.Item>
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Form>
          </Drawer>


          <Drawer
            title="Edit Fence"
            placement="right"
            closable={false}
            width={"75%"}
            onClose={() => { this.setState({ showEditDrawer: false }); }}
            visible={this.state.showEditDrawer}
            getContainer={false}
            style={{ position: 'absolute' }}
            drawerStyle={{ maxWidth: '600px' }}
          >
            <EditForm
              item={this.state.currentSelection}
              onSubmit={(updatedData: any, item: any) => {
                console.log('hok got updated thing', updatedData, item);
                const fence = item;
                // in theory this should update the item in-place buuuut we'll see
                fence.properties = {
                  ...fence.properties,
                  ...updatedData,
                };

                let updatedFences = this.state.localFences || this.state.userFences || [];
                updatedFences = updatedFences.map((x: any) => {
                  if (x.id === item.id) {
                    return fence;
                  } else {
                    return x;
                  }
                });

                console.log('sending these fences...', updatedFences, fence, item);

                this.setState({
                  localFences: updatedFences,
                  showEditDrawer: false,
                  currentSelection: null,
                }); // , this.postFenceUpdates);
              }}
            />
          </Drawer>


          <div style={{
            width: '100vw',
            height: '75vh',
            maxHeight: '75vh',
            minHeight: '33vh',
            overflow: 'hidden',
            position: 'relative',
            margin: '0 auto',
          }}>

            <Map
              style={'mapbox://styles/mapbox/streets-v11'}
              center={this.state.isTrackingCenter ? this.state.center : undefined}
              containerStyle={mapStyle}
              onDragStart={() => { this.setState({ isTrackingCenter: false }) }}
              onTouchStart={() => { this.setState({ isTrackingCenter: false }) }}
              onMouseDown={() => { this.setState({ isTrackingCenter: false }) }}
              zoom={this.state.isTrackingCenter ? [16.5] : undefined}
            >

              <DrawControl
                ref={(current) => { this.drawControlRef = current; }}
                controls={{
                  point: false,
                  line_string: false,
                  polygon: true,
                  trash: false,
                  combine_features: true,
                  uncombine_features: true,
                }}
                onDrawSelectionChange={this.onMapSelection}
                onDrawUpdate={this.onLocalMapUpdate}
                onDrawCombine={this.onLocalMapUpdate}
                onDrawCreate={(data: any) => {
                  this.onLocalMapUpdate();
                  this.onMapSelection(data, true);
                }}
                onDrawDelete={this.onLocalMapUpdate}
              />

              <Layer type="circle" paint={{
                "circle-radius": {
                  stops: [
                    [0, 0],
                    [20, this.metersToPixelsAtMaxZoom(this.state.currentUserCoords.accuracy, this.state.currentUserCoords.latitude)]
                  ],
                  base: 2,
                },
                "circle-color": "#5b94c6",
                "circle-opacity": 0.175
              }}>
                <Feature coordinates={this.getCurrentLongLat()} />
              </Layer>

              <Layer type="circle" paint={{
                "circle-radius": 5,
                "circle-color": "#5b94c6",
                "circle-opacity": 1
              }}>
                <Feature coordinates={this.getCurrentLongLat()} />
              </Layer>


              {printedLabels}

            </Map>
          </div>


          {
            this.state.mode === ADMIN_STATE.EDIT_VIEW ?
              <Card
                title="Edit Mode"
                style={{
                  maxWidth: '500px',
                  width: '95vw',
                  margin: 'calc(-2em - 6vh) auto 0',
                  zIndex: 500,
                  filter: 'drop-shadow(0px 0px 12px rgba(0,0,0,0.25))',
                }}>

                <ButtonGroup>
                  {
                    this.state.currentSelection &&
                    this.drawControlRef &&
                    <Button
                      type="primary"
                      icon={"edit"}
                      onClick={() => {
                        this.setState({
                          showEditDrawer: !this.state.showEditDrawer,
                        })
                      }}
                    >
                      Edit Fence
            </Button>
                  }
                  {
                    this.state.currentSelection &&
                    <Popconfirm
                      title="Are you sure you want to delete this fence? This can not be undone."
                      onConfirm={() => {
                        const newFences = (this.state.localFences || this.state.userFences || []).filter((x: any) => {
                          return x.id !== (this.state.currentSelection as any).id;
                        });
                        this.setState({
                          localFences: newFences,
                          currentSelection: null,
                        }, () => {
                          this.drawLocalFences();
                        });
                      }}
                      onCancel={() => {
                        // do nothing
                      }}
                      okText="Delete"
                      cancelText="Cancel"
                    >
                      <Button
                        type="danger"
                        icon="delete"
                      >
                        Delete Fence
                    </Button>
                    </Popconfirm>
                  }

                  {
                    this.hasUnsavedChanges() &&
                    <>
                      <Button
                        type="primary"
                        icon={"edit"}
                        onClick={() => {
                          // save
                          this.postFenceUpdates();
                        }}
                      >
                        Save Changes
                    </Button>

                      <Popconfirm
                        title="Are you sure you want to undo your local changes? This can not be undone."
                        onConfirm={() => {
                          this.setState({
                            localFences: null,
                            currentSelection: null,
                            mode: ADMIN_STATE.STATUS_VIEW,
                          }, () => {
                            this.drawFences();
                          });
                        }}
                        onCancel={() => {
                          // do nothing
                        }}
                        okText="Confirm"
                        cancelText="Cancel"
                      >
                        <Button
                          type="danger"
                          icon="delete"
                        >
                          Undo Changes
                    </Button>
                      </Popconfirm>
                    </>
                  }
                </ButtonGroup>
              </Card>
              :
              <div>
                <StatusView
                  wasLastSeen={this.state.wasLastSeen}
                  currentDisplayLabel={this.state.positionLabel}
                  fences={this.state.userFences}
                  autoUpdate={this.state.prefs.autoUpdate}
                />
              </div>

          }



        </Content>
        <Footer style={{ textAlign: 'center' }}>
          <Radio.Group>
            <Radio.Button onClick={() => window.location.reload()}><Icon type="logout" /> Log Out</Radio.Button>
          </Radio.Group>
        </Footer>
      </Layout>
    );
  }

  private addBodyClass(className: string) {
    document.body.classList.add(className);
  }

  private removeBodyClass(className: string) {
    document.body.classList.remove(className);
  }

  private hasUnsavedChanges = () => {
    return this.state.localFences && JSON.stringify(this.state.userFences) !== JSON.stringify(this.state.localFences);
  }

  private toggleOptionsPanel = () => {
    this.setState({
      showOptionsPanel: !this.state.showOptionsPanel,
    })
  };

  private toggleMode = () => {

    // if(this.state.mode === ADMIN_STATE.EDIT_VIEW && this.hasUnsavedChanges()){

    // }

    const newMode = this.state.mode === ADMIN_STATE.EDIT_VIEW ? ADMIN_STATE.STATUS_VIEW : ADMIN_STATE.EDIT_VIEW;

    this.setState({
      mode: newMode,
      currentSelection: null,
    }, () => {
      switch (newMode) {
        default:
        case ADMIN_STATE.STATUS_VIEW:
          this.drawServerFences();
          this.removeBodyClass('edit-mode');
          break;
        case ADMIN_STATE.EDIT_VIEW:
          this.drawLocalFences();
          this.addBodyClass('edit-mode');
          break;
      }
    });
  }

  private drawFences() {
    if (this.state.mode === ADMIN_STATE.EDIT_VIEW) {
      this.drawLocalFences();
    } else {
      this.drawServerFences();
    }
  }

  private drawServerFences() {
    if (!this.drawControlRef) {
      setTimeout(this.drawServerFences.bind(this), 500);
      return;
    }

    this.drawControlRef.draw.deleteAll();
    (this.state.userFences || []).forEach((fence: any) => {
      this.drawControlRef.draw.add({
        id: fence.id,
        type: 'Feature',
        ...fence,
      });
    });
  }

  private drawLocalFences() {
    if (!this.drawControlRef) {
      setTimeout(this.drawLocalFences.bind(this), 500);
      return;
    }

    this.drawControlRef.draw.deleteAll();
    (this.state.localFences || this.state.userFences || []).forEach((fence: any) => {
      this.drawControlRef.draw.add({
        id: fence.id,
        type: 'Feature',
        ...fence,
      });
    });
  }
}
