import React from 'react';
import App from './App';
import Admin from './Admin';
import { Router, Route, IndexRoute, Link, browserHistory } from 'react-router'

export default class AppRouter extends React.Component {

  render() {
    return (
      <Router history={browserHistory}>
        <Route path="/" component={App} />
        <Route path="admin" component={Admin} />
      </Router>
    );
  }
}
