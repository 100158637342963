import React from 'react';
import { Form, Input, Button } from 'antd';

export default class EditForm extends React.Component<any, any> {
  render(){
    if(!this.props.item){
      return null;
    }

    return (
      <div>
        <hr />
        <Form onSubmit={(evt)=>{
          evt.preventDefault();
          const data = new FormData(evt.target as HTMLFormElement);
          this.props.onSubmit({
            name: data.get('placeName'),
          }, this.props.item);
          console.log('form submitted', data);
        }}>
          <Form.Item label="Place Name">
            <Input defaultValue={this.props.item.properties.name || ''} type="text" name="placeName" placeholder="The Middle School" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
        </Form.Item>
        </Form>
      </div>
    )
  }
}