export default class GPS {
	private constructor() { }

	private static currentWatch: number | null = null;

	private static listeners: Function[] = [];

	public static onUpdate(cb: Function) {
		GPS.listeners.push(cb);
	}

	private static fireUpdate(data: Coordinates) {
		for (const idx in GPS.listeners) {
			GPS.listeners[idx](data);
		}
	}

	public static startWatchingPosition() {
		GPS.currentWatch = window.navigator.geolocation.watchPosition((pos: Position) => {
			const coords = pos.coords;

			GPS.fireUpdate(coords);
		}, (err) => {
			console.log('error watching local position');
		}, {
				enableHighAccuracy: true,
			});
	}

	public static stopWatchingPosition() {
		if(GPS.currentWatch){
			window.navigator.geolocation.clearWatch(GPS.currentWatch);
		}
	}

	public static getCurrentPosition(): Promise<Coordinates> {
		return new Promise((resolve, reject) => {
			window.navigator.geolocation.getCurrentPosition((pos: Position) => {
				const coords: Coordinates = pos.coords;
				resolve(coords);
			}, (err) => {
				reject('error getting local position: ' + err);
			}, {
					enableHighAccuracy: window.location.protocol.indexOf('https') !== -1,
				});
		});

	}

	private stopWatchingPosition() {
		if (GPS.currentWatch) {
			window.navigator.geolocation.clearWatch(GPS.currentWatch);
		}
	}

}