import React from 'react';
import logo from './logo.svg';
import './App.css';
import { API } from './api';
import Typewriter from './Typewriter';
import * as MapboxGL from 'mapbox-gl';
import ReactMapboxGl, { Layer, Feature, GeoJSONLayer, Source } from 'react-mapbox-gl';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { centerOnAlden } from './fixtures';

const { token, styles } = require('./config.json');
const Map = ReactMapboxGl({ accessToken: token });

const symbolLayout: MapboxGL.SymbolLayout = {
  'text-field': '{place}',
  'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
  'text-offset': [0, 0.6],
  'text-anchor': 'top'
};
const symbolPaint: MapboxGL.SymbolPaint = {
  'text-color': 'white'
};

const circleLayout: MapboxGL.CircleLayout = { visibility: 'visible' };
const circlePaint: MapboxGL.CirclePaint = {
  'circle-color': 'white'
};
const mapStyle = {
  flex: 1
};

export default class App extends React.Component {
  state = {
    currentPositionName: 'Unavailable',
    wasLastSeen: false,
    background: this.getRandomBackground(),
    mixMode: this.getRandomMixMode() as any,
    lastChange: -Infinity,
    backgroundPosition: 'bottom left',
  }

  componentDidMount() {
    this.updateLocation();
  }

  private updateLocation() {
    fetch(API.getRoute('current/label'))
      .then(x => x.json())
      .then(data => {
        if (Date.now() - this.state.lastChange > 60000 * 10) {
          this.setState({
            background: this.getRandomBackground(),
            mixMode: this.getRandomMixMode() as any,
            lastChange: Date.now(),
            currentPositionName: data.label,
            wasLastSeen: data.lastSeen,
            backgroundPosition: this.getRandomPosition(),
          }, () => {
            setTimeout(() => {
              const pos = this.state.backgroundPosition;
              const newPos = this.getRandomPosition();
              console.log('here..', pos, newPos);
              this.setState({
                backgroundPosition: newPos,
              })
            }, 10);
          });
        } else {
          this.setState({
            currentPositionName: data.label,
            wasLastSeen: data.lastSeen,
          });
        }
      }).catch(err => {
        console.log('error on mount', err);
      }).finally(() => {
        setTimeout(this.updateLocation.bind(this), 10000);
      });
  }

  getRandomBackground() {
    const sets = [
      ['#f64f59', '#c471ed', '#12c2e9'],
      ['#654ea3', '#eaafc8'],
      ['#c6ffdd', '#fbd786', '#f7797d'],
      ['#0f2027', '#203a43', '#2c5364'],
      ['#8e2de2', '#4a00e0'],
      ['#c31432', '#240b36'],
      ['#8360c3', '#2ebf91'],
      ['#009FFF', '#ec2F4B'],
      ['#59c173', '#a17fe0', '#5d26c1'],
      ['#a8c0ff', '#3f2b96'],
      ['#fffbd5', '#b20a2c'],
      ['#C33764', '#1D2671'],
      ['#76b852', '#8DC26F'],
      ['#00c6ff', '#0072ff'],
      ['#d53369', '#cbad6d'],
      ['#ED4264', '#FFEDBC'],
      ['#24C6DC', '#514A9D'],
      ['#16A085', '#F4D03F'],
      ['#536976', '#292E49'],
    ];

    const gradients = [
      'linear-gradient(to top right, {{colors}})',
      'linear-gradient(to top left, {{colors}})',
      'linear-gradient(to bottom left, {{colors}})',
      'linear-gradient(to bottom right, {{colors}})',
    ];

    const set = sets[Math.floor(Math.random() * sets.length)];
    let grad = gradients[Math.floor(Math.random() * gradients.length)];
    grad = grad.replace('{{colors}}', set.join(', '));

    return grad;
  }

  getRandomPosition() {
    const a = ['bottom', 'center', 'top'];
    const b = ['left', 'right', 'center'];

    return `${a[Math.floor(Math.random() * a.length)]} ${b[Math.floor(Math.random() * b.length)]}`
  }

  getRandomMixMode() {
    const mixes = [
      'color',
      'color-burn',
      'hard-light',
      'multiply',
    ];

    return mixes[Math.floor(Math.random() * mixes.length)];
  }

  render() {
    return (
      <div className="App">
        <header className="App-header bg1">
          <div className="App-content">
            <h1 className="wordart superhero"><span className="text">Where in the World is <b>Mr.&nbsp;Mikulski?</b></span></h1>
            <h2 className="wordart purple spin-hue">
              {
                this.state.wasLastSeen && this.state.currentPositionName !== 'Unavailable' &&
                <span className="text last-seen">Last seen at</span>
              }
              <Typewriter text={this.state.currentPositionName} />
            </h2>
          </div>
        </header>

        <div className="bg-overlay spin-hue" style={{
          backgroundImage: this.state.background,
          backgroundSize: '250% 250%',
          backgroundPosition: this.state.backgroundPosition,
          transition: `${60000 * 10}ms background-position linear`,
          // mixBlendMode: this.state.mixMode,
        }} />
      </div>
    );
  }
}
